import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
import Develop from "components/features/TwoColWithButtonDev.js";
import Servidores from "components/features/TwoColWithSteps.js";
import Respaldos from "components/features/TwoColWithButtonBackup.js";
import PricingiDrive from "components/pricing/ThreePlansWithHalfPrimaryBackgroundIdrive.js";
import Testimonial from "components/testimonials/SimplePrimaryBackground.js";
import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";
import Footer from "components/footers/FiveColumnCloud.js";
import serverRedundancyIllustrationImageSrc from "images/server-redundancy-illustration.svg";
import serverSecureIllustrationImageSrc from "images/server-secure-illustration.svg";
import devImage from "images/devlanding.png";

import { FloatingWhatsApp } from "react-floating-whatsapp";

import logo from "../images/logo-light.svg"

export default () => {
  return (
    <AnimationRevealPage>
      <Hero />
      <FloatingWhatsApp chatMessage="Buen día                        ¿Cómo podemos apoyarte?"
    statusMessage="Atencion a Clientes - En linea"
      phoneNumber="RQ76DLQ6YMAYI1"
      accountName="Dimmsys"
      allowEsc
      allowClickAway
      notification
      notificationSound 
      darkMode= "true"
      placeholder="Escribe un mensaje aquí"
      messageDelay={1}
      avatar={logo}
      />
      <Features />
      <Develop 
        subheading="Personalizado"
        heading="Desarrollo Web"
        imageSrc={devImage}
        buttonRounded={false}
        textOnLeft={false}
      />
      <Servidores 
        subheading="Confiable."
        heading="Servicio de servidores virtualizados"
        imageSrc={serverRedundancyIllustrationImageSrc}
        buttonRounded={false}
      />
      <Respaldos 
        subheading=""
        heading="Sistemas de respaldo de información."
        imageSrc={serverSecureIllustrationImageSrc}
        buttonRounded={false}
        textOnLeft={false}
      />
      {/*<Testimonial />
      <FAQ />*/}
      <Footer />
    </AnimationRevealPage>
  );
}
