import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";


import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Blog from "components/blogs/GridWithFeaturedPost.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStartedLight.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Preciosidrive from "components/pricing/ThreePlansWithHalfPrimaryBackgroundIdrive.js"

import logo from "../images/logo-light.svg"

import { FloatingWhatsApp } from 'react-floating-whatsapp'

const HighlightedText = tw.span`text-primary-500`

export default () => {
  return (
    <AnimationRevealPage>
      <Hero />
      <FloatingWhatsApp chatMessage="Buen día                        ¿Cómo podemos apoyarte?"
    statusMessage="Atencion a Clientes - En linea"
      phoneNumber="RQ76DLQ6YMAYI1"
      accountName="Dimmsys"
      allowEsc
      allowClickAway
      notification
      notificationSound 
      darkMode= "true"
      placeholder="Escribe un mensaje aquí"
      messageDelay={1}
      avatar={logo}
      />
      <FeatureStats/>
      <Features 
        heading={<>Caractetristicas <HighlightedText>Increibles</HighlightedText></>}
      />
      <MainFeature
        heading={<>Manten segura <HighlightedText>tu información</HighlightedText></>}
      />
      <Preciosidrive />
      <Testimonial 
        heading={<>Seguridad de <HighlightedText>tu personal</HighlightedText></>}
      />
      <Pricing 
        heading={<>Flexible <HighlightedText>Plans</HighlightedText></>}
      />
      <FAQ
        heading={<>Any <HighlightedText>Questions ?</HighlightedText></>}
      />
      <Blog
        subheading="Blog"
        heading={<>We love <HighlightedText>Writing</HighlightedText></>}
      />
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
}
