import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/lightprivacy.js";
import Footer from "components/footers/FiveColumnDarkPrivacy";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Terminos y condiciones" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
          <h1>TÉRMINOS Y CONDICIONES</h1>
<p>Este contrato describe los términos y condiciones generales (en adelante únicamente
"TÉRMINOS Y CONDICIONES") aplicables al uso de los contenidos, productos y
servicios ofrecidos a través del sitio www.dimmsys.com (en adelante, "SITIO WEB"),
del cual es titular RCA COMPUTER CONSULTING S.A.S. DE C.V. (en adelante,
"TITULAR"). Cualquier persona que desee acceder o hacer uso del sitio o los servicios
que en él se ofrecen, podrá hacerlo sujetándose a los presentes TÉRMINOS Y
CONDICIONES, así como a políticas y principios incorporados al presente documento.
En todo caso, cualquier persona que no acepte los presentes términos y condiciones,
deberá abstenerse de utilizar el SITIO WEB y/o adquirir los productos y servicios que en
su caso sean ofrecidos.</p>
<h2>I. DEL OBJETO.</h2>
<p>El objeto de los presentes TÉRMINOS Y CONDICIONES es regular el acceso y la
utilización del SITIO WEB, entendiendo por este cualquier tipo de contenido, producto o
servicio que se encuentre a disposición del público en general dentro del dominio:
www.dimmsys.com.</p>
<p>El TITULAR se reserva la facultad de modificar en cualquier momento y sin previo
aviso, la presentación, los contenidos, la funcionalidad, los productos, los servicios, y la
configuración que pudiera estar contenida en el SITIO WEB; en este sentido, el
USUARIO reconoce y acepta que RCA COMPUTER CONSULTING S.A.S. DE C.V. en
cualquier momento podrá interrumpir, desactivar o cancelar cualquiera de los elementos
que conforman el SITIO WEB o el acceso a los mismos.</p>
<p>Además del costo de la conexión a internet en virtud de los servicios que el USUARIO
tenga contratados con algún proveedor de telecomunicaciones, parte de los contenidos
o servicios ofrecidos en el sitio www.dimmsys.com o, en su caso, por terceros a través
del SITIO WEB pueden estar sujetos a la contratación previa del contenido, producto o
servicio, en cuyo caso se especificará de forma clara y se pondrá a disposición del
USUARIO las condiciones generales o particulares por las que se rija el acceso a
dichos contenidos.</p>
<p>El acceso a parte de los contenidos y servicios del SITIO WEB podrá realizarse previa
suscripción o registro previo del USUARIO.</p>
2/7
<p>El SITIO WEB se encuentra dirigido exclusivamente a personas que cuenten con la
mayoría de edad (mayores de 18 años); en este sentido, RCA COMPUTER
CONSULTING S.A.S. DE C.V. declina cualquier responsabilidad por el incumplimiento
de este requisito.</p>
<p>El SITIO WEB está dirigido principalmente a USUARIOS residentes en la República
Mexicana, por lo cual, RCA COMPUTER CONSULTING S.A.S. DE C.V. no asegura
que el SITIO WEB cumpla total o parcialmente con la legislación de otros países, de
forma que, si el USUARIO reside o tiene su domicilio establecido en otro país y decide
acceder o utilizar el SITIO WEB lo hará bajo su propia responsabilidad y deberá
asegurarse de que tal acceso y navegación cumple con la legislación local que le es
aplicable, no asumiendo RCA COMPUTER CONSULTING S.A.S. DE C.V. ninguna
responsabilidad que se pueda derivar de dicho acto.</p>
<p>Se hace del conocimiento del USUARIO que el TITULAR podrá administrar o gestionar
el SITIO WEB de manera directa o a través de un tercero, lo cual no modifica en ningún
sentido lo establecido en los presentes TÉRMINOS Y CONDICIONES.</p>
<h2>II. DEL USUARIO.</h2>
<p>El acceso o utilización del SITIO WEB, así como de los recursos habilitados para
interactuar entre los USUARIOS, o entre el USUARIO y el TITULAR tales como medios
para realizar publicaciones o comentarios, confiere la condición de USUARIO del SITIO
WEB, por lo que quedará sujeto a los presentes TÉRMINOS Y CONDICIONES, así
como a sus ulteriores modificaciones, sin perjuicio de la aplicación de la legislación
aplicable, por tanto, se tendrán por aceptados desde el momento en el que se accede al
SITIO WEB. Dada la relevancia de lo anterior, se recomienda al USUARIO revisar las
actualizaciones que se realicen a los presentes TÉRMINOS Y CONDICIONES.</p>
<p>Es responsabilidad del USUARIO utilizar el SITIO WEB de acuerdo a la forma en la que
fue diseñado; en este sentido, queda prohibida la utilización de cualquier tipo de
software que automatice la interacción o descarga de los contenidos o servicios
proporcionados a través del SITIO WEB. Además, el USUARIO se compromete a
utilizar la información, contenidos o servicios ofrecidos a través del SITIO WEB de
manera lícita, sin contravenir lo dispuesto en los presentes TÉRMINOS Y
CONDICIONES, la moral o el orden público, y se abstendrá de realizar cualquier acto
que pueda suponer una afectación a los derechos de terceros, o perjudique de algún
modo el funcionamiento del SITIO WEB.</p>
<p>Así mismo, el usuario se compromete a proporcionar información lícita y veraz en los
3/7
formularios habilitados en el SITIO WEB, en los cuales el usuario tenga que
proporcionar ciertos datos o información para el acceso a algún contenido, producto o
servicio ofrecido por el propio SITIO WEB. En todo caso, el USUARIO notificará de
forma inmediata al TITULAR acerca de cualquier hecho que permita suponer el uso
indebido de la información registrada en dichos formularios, tales como, robo, extravío,
o acceso no autorizado a cuentas y/o contraseñas, con el fin de proceder a su
inmediata cancelación.</p>
<p>RCA COMPUTER CONSULTING S.A.S. DE C.V. se reserva el derecho de retirar todos
aquellos comentarios y aportaciones que vulneren la ley, el respeto a la dignidad de la
persona, que sean discriminatorios, atenten contra los derechos de tercero o el orden
público, o bien, que a su juicio no resulten adecuados para su publicación.
En cualquier caso, RCA COMPUTER CONSULTING S.A.S. DE C.V. no será
responsable de las opiniones vertidas por los USUARIOS a través de comentarios o
publicaciones que estos realicen.
El sólo acceso al SITIO WEB no supone el establecimiento de ningún tipo de relación
entre el TITULAR y el USUARIO.
Al tratarse de un SITIO WEB dirigido exclusivamente a personas que cuenten con la
mayoría de edad, el USUARIO manifiesta ser mayor de edad y disponer de la
capacidad jurídica necesaria para sujetarse a los presentes TÉRMINOS Y
CONDICIONES.</p>
<h2>III. DEL ACCESO Y NAVEGACIÓN EN EL SITIO WEB.</h2>
<p>El TITULAR no garantiza de ningún modo la continuidad y disponibilidad de los
contenidos, productos o servicios ofrecidos a través del SITIO WEB, no obstante, el
TITULAR llevará a cabo las acciones que de acuerdo a sus posibilidades le permitan
mantener el buen funcionamiento del SITO WEB, sin que esto suponga alguna
responsabilidad de parte de RCA COMPUTER CONSULTING S.A.S. DE C.V.
De igual forma RCA COMPUTER CONSULTING S.A.S. DE C.V. no será responsable
ni garantiza que el contenido o software al que pueda accederse a través del SITIO
WEB, se encuentre libre de errores, software malicioso, o que pueda causar algún daño
a nivel de software o hardware en el equipo a través del cual el USUARIO accede al
SITIO WEB.
El TITULAR tampoco se hace responsable de los daños que pudiesen ocasionarse por
un uso inadecuado del SITIO WEB. En ningún caso RCA COMPUTER CONSULTING
S.A.S. DE C.V. será responsable por las pérdidas, daños o perjuicios de cualquier tipo
que surjan por el sólo acceso o utilización del SITIO WEB.</p>

<h2>IV. POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS.</h2>
<p>De conformidad con lo establecido en la Ley Federal de Protección de Datos
Personales en Posesión de Particulares, el TITULAR se compromete a adoptar las
medidas necesarias que estén a su alcance para asegurar la privacidad de los datos
personales recabados de forma que se garantice su seguridad, se evite su alteración,
pérdida o tratamiento no autorizado.
Además, a efecto de dar cumplimiento a lo establecido en la Ley Federal de Protección
de Datos Personales en Posesión de Particulares, todo dato personal que sea recabado
a través del SITIO WEB, será tratado de conformidad con los principios de licitud,
calidad, finalidad, lealtad, y responsabilidad. Todo tratamiento de datos personales
quedará sujeto al consentimiento de su titular. En todo caso, la utilización de datos
financieros o patrimoniales, requerirán de autorización expresa de sus titulares, no
obstante, esta podrá darse a través del propio SITIO WEB utilizando los mecanismos
habilitados para tal efecto, y en todo caso se dará la mayor diligencia y cuidado a este
tipo de datos. Lo mismo ocurrirá en el caso de datos personales sensibles,
considerando por estos aquellos que debido a una utilización indebida puedan dar
origen a discriminación o su divulgación conlleve un riesgo para el titular.
En todo momento se procurará que los datos personales contenidos en las bases de
datos o archivos que en su caso se utilicen, sean pertinentes, correctos y actualizados
para los fines para los cuales fueron recabados.
El tratamiento de datos personales se limitará al cumplimiento de las finalidades
previstas en el Aviso de Privacidad el cual se encontrará disponible en la siguiente
dirección electrónica:
www.dimmsys.com/privacidad
El SITIO WEB podrá incluir hipervínculos o enlaces que permitan acceder a páginas
web de terceros distintos de RCA COMPUTER CONSULTING S.A.S. DE C.V. Los
titulares de dichos sitios web dispondrán de sus propias políticas de privacidad y
protección de datos, por lo cual RCA COMPUTER CONSULTING S.A.S. DE C.V. no
asume ningún tipo de responsabilidad por los datos que san facilitados por el
USUARIO a través de cualquier sitio web distinto a www.dimmsys.com.
RCA COMPUTER CONSULTING S.A.S. DE C.V. se reserva el derecho a modificar su
Política de Privacidad, de acuerdo a sus necesidades o derivado de algún cambio en la
legislación. El acceso o utilización del SITIO WEB después de dichos cambios,
implicará la aceptación de estos cambios.
Por otra parte, el acceso al SITIO WEB puede implicar la utilización de cookies, las
cuales, son pequeñas cantidades de información que se almacenan en el navegador
utilizado por el USUARIO. Las cookies facilitan la navegación, la hacen más amigable, y
no dañan el dispositivo de navegación, para ello, pueden recabar información para
ingresar al SITIO WEB, almacenar las preferencias del USUARIO, así como la
interacción que este tenga con el SITIO WEB, como por ejemplo: la fecha y hora en la
que se accede al SITIO WEB, el tiempo que se ha hecho uso de este, los sitios
visitados antes y después del mismo, el número de páginas visitadas, la dirección IP de
la cual accede el usuario, la frecuencia de visitas, etc.
Este tipo de información será utilizada para mejorar el SITIO WEB, detectar errores, y
posibles necesidades que el USUARIO pueda tener, lo anterior a efecto de ofrecer a los
USUARIOS servicios y contenidos de mejor calidad. En todo caso, la información que
se recopile será anónima y no se identificará a usuarios individuales.
En caso de que el USUARIO no desee que se recopile este tipo de información deberá
deshabilitar, rechazar, restringir y/o eliminar el uso de cookies en su navegador de
internet. Los procedimientos para realizar estas acciones pueden diferir de un
navegador a otro; en consecuencia, se sugiere revisar las instrucciones facilitadas por
el desarrollador del navegador. En el supuesto de que rechace el uso de cookies (total o
parcialmente) el USUARIO podrá continuar haciendo uso del SITIO WEB, aunque
podrían quedar deshabilitadas algunas de las funciones del mismo.
Es posible que en el futuro estas políticas respecto a las cookies cambien o se
actualicen, por ello es recomendable revisar las actualizaciones que se realicen a los
presentes TÉRMINOS Y CONDICIONES, con objetivo de estar adecuadamente
informado sobre cómo y para qué utilizamos las cookies que se generan al ingresar o
hacer uso del SITIO WEB.</p>
<h2>V. POLÍTICA DE ENLACES.</h2>
<p>El SITIO WEB puede contener enlaces, contenidos, servicios o funciones, de otros
sitios de internet pertenecientes y/o gestionados por terceros, como por ejemplo
imágenes, videos, comentarios, motores de búsqueda, etc.</p>
<p>La utilización de estos enlaces, contenidos, servicios o funciones, tiene por objeto
mejorar la experiencia del USUARIO al hacer uso del SITIO WEB, sin que pueda
considerarse una sugerencia, recomendación o invitación para hacer uso de sitios
externos. RCA COMPUTER CONSULTING S.A.S. DE C.V. en ningún caso revisará o
controlará el contenido de los sitios externos, de igual forma, no hace propios los
productos, servicios, contenidos, y cualquier otro material existente en los referidos
sitios enlazados; por lo cual, tampoco se garantizará la disponibilidad, exactitud,
veracidad, validez o legalidad de los sitios externos a los que se pueda tener acceso a
través del SITIO WEB. Así mismo, el TITULAR no asume ninguna responsabilidad por
los daños y perjuicios que pudieran producirse por el acceso o uso, de los contenidos,
productos o servicios disponibles en los sitios web no gestionados por RCA
COMPUTER CONSULTING S.A.S. DE C.V. a los que se pueda acceder mediante el
SITIO WEB.</p>
<p>Los USUARIOS o terceros que realicen o publiquen un enlace web desde una página
web externa, a este SITIO WEB deberán tomar en cuenta lo siguiente:
No se permite la reproducción (total o parcial) de los contenidos, productos o
servicios disponibles en el SITIO WEB sin la autorización expresa de RCA
COMPUTER CONSULTING S.A.S. DE C.V. o su titular. Tampoco se permitirán
manifestaciones falsas, inexactas o incorrectas sobre el SITIO WEB, ni sobre sus
contenidos, productos o servicios, pudiendo RCA COMPUTER CONSULTING
S.A.S. DE C.V. restringir el acceso al SITIO WEB a toda aquella persona que
incurra en este tipo de actos.</p>
<p>El establecimiento de un enlace al SITIO WEB desde cualquier sitio externo, no
implicará la existencia de alguna relación entre RCA COMPUTER CONSULTING
S.A.S. DE C.V. y el titular del sitio web desde el cual se realice, tampoco implicará
el conocimiento de RCA COMPUTER CONSULTING S.A.S. DE C.V. de los
contenidos, productos o servicios ofrecidos en los sitios externos desde los cuales
se pueda acceder al SITIO WEB.</p>
<h2>VI. POLÍTICA EN MATERIA DE PROPIEDAD INTELECTUAL E INDUSTRIAL.</h2>
<p>RCA COMPUTER CONSULTING S.A.S. DE C.V. por sí o como parte cesionaria, es
titular de todos los derechos de propiedad intelectual e industrial del SITIO WEB,
entendiendo por este el código fuente que hace posible su funcionamiento así como las
imágenes, archivos de audio o video, logotipos, marcas, combinaciones de colores,
estructuras, diseños y demás elementos que lo distinguen. Serán, por consiguiente,
protegidas por la legislación mexicana en materia de propiedad intelectual e industrial,
así como por los tratados internacionales aplicables. Por consiguiente, queda
expresamente prohibida la reproducción, distribución, o difusión de los contenidos del
SITIO WEB, con fines comerciales, en cualquier soporte y por cualquier medio, sin la
autorización de RCA COMPUTER CONSULTING S.A.S. DE C.V.</p>
<p>El USUARIO se compromete a respetar los derechos de propiedad intelectual e
industrial del TITULAR. No obstante, además de poder visualizar los elementos del
SITIO WEB podrá imprimirlos, copiarlos o almacenarlos, siempre y cuando sea
exclusivamente para su uso estrictamente personal.</p>
<p>Por otro lado, el USUARIO, se abstendrá de suprimir, alterar, o manipular cualquier
elemento, archivo, o contenido, del SITIO WEB, y por ningún motivo realizará actos
tendientes a vulnerar la seguridad, los archivos o bases de datos que se encuentren
protegidos, ya sea a través de un acceso restringido mediante un usuario y contraseña,
o porque no cuente con los permisos para visualizarlos, editarlos o manipularlos.</p>
<p>En caso de que el USUARIO o algún tercero consideren que cualquiera de los
contenidos del SITIO WEB suponga una violación de los derechos de protección de la
propiedad industrial o intelectual, deberá comunicarlo inmediatamente a RCA
COMPUTER CONSULTING S.A.S. DE C.V. a través de los datos de contacto
disponibles en el propio SITIO WEB y/o a través del siguiente medio:
Correo electrónico: contacto@dimmsys.com</p>
<h2>VII. LEGISLACIÓN Y JURISDICCIÓN APLICABLE.</h2>
<p>RCA COMPUTER CONSULTING S.A.S. DE C.V. se reserva la facultad de presentar las
acciones civiles o penales que considere necesarias por la utilización indebida del
SITIO WEB, sus contenidos, productos o servicios, o por el incumplimiento de los
presentes TÉRMINOS Y CONDICIONES.
La relación entre el USUARIO y RCA COMPUTER CONSULTING S.A.S. DE C.V. se
regirá por la legislación vigente en México, específicamente en Querétaro. De surgir
cualquier controversia en relación a la interpretación y/o a la aplicación de los presentes
TÉRMINOS Y CONDICIONES, las partes se someterán a la jurisdicción ordinaria de los
tribunales que correspondan conforme a derecho en el estado al que se hace
referencia.</p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
