import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero2 from "components/hero/TwoColumnWithVideo.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import Footer from "components/footers/FiveColumnDark.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration-2.svg";
import Slider from "components/cards/ThreeColSlider.js";
import Header from "components/headers/light.js";
import "../../src/cookie-consent.js"

import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  

import CookieConsent from "cookie-consent-js";

import logo from "../images/logo-light.svg"

import { FloatingWhatsApp } from 'react-floating-whatsapp'

export default () => (
  
  <AnimationRevealPage>
    <script src="/node_modules/cookie-consent-js/src/cookie-consent.js"></script>
    <Header />
    {/*<FloatingWhatsApp chatMessage="Buen día                        ¿Cómo podemos apoyarte?"
    statusMessage="Atencion a Clientes - En linea"
      phoneNumber="4423205530"
      accountName="Dimmsys"
      allowEsc
      allowClickAway
      notification
      notificationSound 
      darkMode= "true"
      placeholder="Escribe un mensaje aquí"
      messageDelay={1}
      avatar={logo}
/>*/}

    <Hero /> {/* Inicio */}
    <Features /> {/* Soluciones destacadas */}
    <MainFeature /> {/* MiContador */}
    <Portfolio /> {/* Servicios */}
    <MainFeature2 /> {/* Proyectos */}
    <Slider/> {/* Marcas */}
    <FAQ
      imageSrc={customerSupportIllustrationSrc}
      imageContain={true}
      imageShadow={false}
      subheading="Asistencia técnica"
      heading={
        <>
         Necesito <span tw="text-primary-500">soporte técnico</span>
        </>
      }
    /> {/* Linea de comunicación */}
    <ContactUsForm /> {/* Contacto */}
    
    <Footer />
  </AnimationRevealPage>
);
