import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl } from "components/misc/Layouts";
import tw from "twin.macro";
// eslint-disable-next-line
import styled from "styled-components";
import { css } from "styled-components/macro"; 
import GitHubButton from "react-github-btn";

import { LogoLink } from "components/headers/light.js";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";

import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as LinkedInIcon } from "images/linkedin-icon.svg";
import { ReactComponent as TwitterIcon } from "images/instagram.svg";
import { ReactComponent as YoutubeIcon } from "images/tiktok.svg";

import logo from "images/logo.svg";

import logolight from "images/logo-light.svg"

import { FloatingWhatsApp } from 'react-floating-whatsapp'

/* Hero */
const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 lg:mr-12 last:mr-0 text-gray-700 border-gray-400 hocus:border-gray-700 `;
const PrimaryNavLink = tw(
  NavLink
)`text-gray-100 bg-primary-500 px-6 py-3 border-none rounded hocus:bg-primary-900 focus:shadow-outline`;
const HeroRow = tw(Row)`max-w-xl flex-col justify-between items-center py-20 lg:py-24 mx-auto`;

const Heading = tw(HeadingBase)`text-center text-primary-900 leading-snug`;
const Description = tw(DescriptionBase)`mt-4 text-center lg:text-base text-gray-700 max-w-lg mx-auto lg:mx-0`;

const SocialLinksContainer = tw.div`mt-4 text-center `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-primary-500 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default () => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */
  window.gtag("js", new Date());
  window.gtag("config", "UA-45799926-9");

  const downloadUrl = "/treact-ui.zip"
  React.useEffect(() => {
    var iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = downloadUrl
    document.body.appendChild(iframe);
  }, [])

  return (
    <AnimationRevealPage disabled>
      <FloatingWhatsApp chatMessage="Buen día                        ¿Cómo podemos apoyarte?"
    statusMessage="Atencion a Clientes - En linea"
      phoneNumber="4423205530"
      accountName="Dimmsys"
      allowEsc
      allowClickAway
      notification
      notificationSound 
      darkMode= "true"
      placeholder="Escribe un mensaje aquí"
      messageDelay={1}
      avatar={logolight}
      />
      <Container tw="-mx-8 -mt-8 pt-8 px-8">
        <Content2Xl>
          <NavRow>
            <LogoLink href="/">
              <img src={logo} alt="" />
            </LogoLink>
            <div tw="flex flex-col lg:flex-row items-center">
              <NavLink href="/privacypolicy">
                Politica de privacidad
              </NavLink>
              
              <NavLink href="/micontador">
                Mas timbres
              </NavLink>
              <PrimaryNavLink href="/">
                Inicio
              </PrimaryNavLink>
            </div>
          </NavRow>
          <HeroRow>
            <Heading>¡Gracias!</Heading>
            <Description tw="mt-12">Gracias por adquirir nuestros productos. Esperamos que tu experiencia con nosotros fuera extraordinaria».</Description>
            <div tw="mt-12 text-center">
              Por tu preferencia, nos mantenemos actualizados y enfocados en atenderte como mereces
            </div>
            <div tw="mt-12 text-center">
              Visitanos en nuestras redes sociales.
              <SocialLinksContainer>
              <SocialLink href="https://www.facebook.com/DIMMSYS">
                <FacebookIcon />
              </SocialLink>
              <SocialLink href="https://www.linkedin.com/company/dimmsys-consultoria-en-sistemas-y-ti/?viewAsMember=true">
                <LinkedInIcon />
              </SocialLink>
              <SocialLink href="https://www.tiktok.com/@dimmsys">
                <TwitterIcon />
              </SocialLink>
              <SocialLink href="https://www.tiktok.com/@dimmsys">
                <YoutubeIcon />
              </SocialLink>
            </SocialLinksContainer>
            </div>
          </HeroRow>
        </Content2Xl>
      </Container>
    </AnimationRevealPage>
  );
};
