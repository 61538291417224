import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/lightMicontador.js";
import Hero from "components/hero/TwoColumnWithVideoMiContador";
import Pricing from "components/pricing/ThreePlans";
import Footer from "components/footers/FiveColumnDarkMicontador.js";
import ComoFunciona from "components/hero/MicontadorComofunciona.js"
import Dudas from "components/forms/SimpleContactUsMicontador.js";
import Endimmsys from "components/testimonials/MicontadorEndimmsys.js";
import Autofactura from "components/features/Autofactura";
import logo from "../images/logo-light.svg";
import Caracteristicas from "components/features/ThreeColMiContador";
import Adquiere from "components/testimonials/MicontadorComienza.js";

import { FloatingWhatsApp } from 'react-floating-whatsapp'

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      {/*<FloatingWhatsApp chatMessage="Buen día                        ¿Cómo podemos apoyarte?"
    statusMessage="Atencion a Clientes - En linea"
      phoneNumber="RQ76DLQ6YMAYI1"
      accountName="Dimmsys"
      allowEsc
      allowClickAway
      notification
      notificationSound 
      darkMode= "true"
      placeholder="Escribe un mensaje aquí"
      messageDelay={1}
      avatar={logo}
  />*/}
      
      <Hero />
      <Pricing />
      <ComoFunciona />
      <Pricing />
      <Endimmsys />
      <Pricing />
      <Autofactura />
      <Caracteristicas />
      <Pricing />
      <Adquiere />
      <Footer/>
    </AnimationRevealPage>
  );
};
