import React, {useState} from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as QuoteIconBase } from "images/quotes-l.svg"
import { ReactComponent as ArrowLeftIcon } from "images/arrow-left-3-icon.svg"
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg"

import "slick-carousel/slick/slick.css";

const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8 text-secondary-100`;

const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-4`;
const Heading = tw(SectionHeading)``
const HeadingBlue = tw.h2`font-black text-3xl md:text-4xl leading-snug max-w-3xl`;
const HeadingGreen = tw.h2`font-black text-primary-500 text-3xl md:text-4xl leading-snug max-w-3xl`;
const HeadingPurple = tw.h2`font-black text-purple-500 text-3xl md:text-3xl leading-snug max-w-3xl`;
const TextGray = tw.p`text-gray-500 text-sm md:text-4xl leading-snug max-w-3xl`;



export default ({
  subheading = "",
  heading = "La manera de facturar más fácil y sencilla",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  testimonials = [
    {
      customerName: "David Hanson",
      customerProfile: "CEO, Koalify",
      imageSrc:
        "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.85&w=256&h=256&q=80",
      quote:
        "We have been using servana for about 2 years. And in that time we have had no problem at all. The user interface is really simple to use. Our services scale automatically and we never have to worry about downtimes. is as described."
    },
    {
      customerName: "Serena Davis",
      customerProfile: "Founder, Travana",
      imageSrc:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=256&h=256&q=80",
      quote:
        "We are delighted with the quality and performance of the servers that servana provides. The uptime is amazing and the internet connection is great for the price we are paying."
    },
    {
      customerName: "Timothy Burr",
      customerProfile: "CTO, Coronax",
      imageSrc:
        "https://images.unsplash.com/photo-1580852300654-03c803a14e24?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4.25&w=256&h=256&q=80",
      quote:
        "It has been 8 months since we have switched to servana and it has nothing but an amazing experience. The cost is affordable, support is great, uptime is as described."
    }
  ]
}) => {
  const [sliderRef, setSliderRef] = useState(null)

  return (
    
    <PrimaryBackgroundContainer>
      <Container>
        <center><br/>
          <HeadingBlue>Comienza a facturar tus CFDI 4.0 u obtén</HeadingBlue>
          <HeadingGreen></HeadingGreen><br/>
          <HeadingPurple>el servicio de AutoFactura Dimmsys para tus empresas</HeadingPurple>
        </center>
      </Container>
      <ContentWithPaddingXl>
        <center><a href="#paquetes"><img src={require('../../images/micontador/boton.png')} style={{maxWidth: "320px"}} /></a></center>
      </ContentWithPaddingXl>
      
    </PrimaryBackgroundContainer>
  );
};
