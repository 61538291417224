import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob } from "images/svg-decorator-blob-6.svg";

const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlansContainer = tw.div`flex justify-between flex-col lg:flex-row items-center lg:items-stretch relative`;
const Plan = styled.div`
  ${tw`w-full max-w-sm mt-16 lg:mr-8 lg:last:mr-0 text-center px-8 rounded-lg shadow relative pt-2 text-gray-900 bg-white flex flex-col`}
  .planHighlight {
    ${tw`rounded-t-lg absolute top-0 inset-x-0 h-2`}
  }

  ${props =>
    props.featured &&
    css`
      background: rgb(100,21,255);
      background: linear-gradient(135deg, rgba(0,110,179,1) 0%, rgba(0,163,173,1) 100%);
      ${tw`bg-primary-500 text-gray-100`}
      .planHighlight {
        ${tw`hidden`}
      }
      .duration {
        ${tw`text-gray-200!`}
      }
      ${PlanFeatures} {
        ${tw`border-indigo-500`}
      }
      .feature:not(.mainFeature) {
        ${tw`text-gray-300!`}
      }
      ${BuyNowButton} {
        ${tw`bg-gray-100 text-primary-500 hocus:bg-gray-300 hocus:text-primary-800`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col uppercase leading-relaxed py-8`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .duration {
    ${tw`text-gray-500 font-bold tracking-widest`}
  }
  .featuredText {
    ${tw`text-xs font-bold px-3 rounded py-2 uppercase bg-purple-900 text-white leading-none mt-4 sm:mt-0 w-full sm:w-auto text-center`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 border-t-2 border-b-2 flex-1`}
  .feature {
    ${tw`mt-5 first:mt-0 font-medium`}
    &:not(.mainFeature) {
      ${tw`text-gray-600`}
    }
  }
  .mainFeature {
    ${tw`text-xl font-bold tracking-wide`}
  }
`;

const PlanAction = tw.div`px-4 sm:px-8 xl:px-16 py-8`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full uppercase tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;

const DecoratorBlob = styled(SvgDecoratorBlob)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-1/2 translate-y-1/2`}
`;


export default ({
  subheading = "Compra segura con mercado pago",
  heading = "Paquetes flexibles de acuerdo a tus necesidades de facturación electrónica*",
  description = "",
  plans = null,
  primaryButtonText = "Comprar ahora"
}) => {
  const defaultPlans = [
    {
      name: "Basico",
      price: "$299.00",
      timbres: "100  Facturas CFDI. ",
      duration: "Los precios incluyen IVA.",
      mainFeature: "- Ideal para iniciar tu negocio.",
      features: [""],
      link: "https://articulo.mercadolibre.com.mx/MLM-2993636354-dimmsys-factura-electronica-100-_JM"
    },
    {
      name: "Ejecutivo",
      price: "$449.00",
      timbres: "200 Facturas CFDI.",
      duration: "Los precios incluyen IVA.",
      mainFeature: "- Comprobantes necesarios para ti y tus empleados.",
      features: [""],
      featured: "EL MAS VENDIDO",
      link: "https://articulo.mercadolibre.com.mx/MLM-1635191339-dimmsys-factura-cfdi-40-paquete-200-timbres-_JM"
    },
    {
      name: "Empresarial",
      price: "$999.00",
      timbres: "500 Facturas CFDI.",
      duration: "Los precios incluyen IVA.",
      mainFeature: "-Facturación para tu empresa en crecimiento.",
      features: [""],
      link: "https://articulo.mercadolibre.com.mx/MLM-2046086513-dimmsys-factura-electronica-500-_JM"
    },
  ];

  if (!plans) plans = defaultPlans;

  const highlightGradientsCss = [
    css`
      background: rgb(56, 178, 172);
      background: linear-gradient(115deg, rgba(56, 178, 172, 1) 0%, rgba(129, 230, 217, 1) 100%);
    `
  ];

  return (
    <Container id="paquetes">
      <ContentWithPaddingXl>
        <HeaderContainer>

          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              {!plan.featured && <div className="planHighlight" css={highlightGradientsCss[index % highlightGradientsCss.length]} />}
              <PlanHeader>
                {plan.featured && <span className="featuredText">{plan.featured}</span>}
                <span className="name">{plan.name}</span>
                <span className="price">{plan.timbres}</span>
                <span className="price">{plan.price}</span>
                <span className="duration">{plan.duration}</span>
              </PlanHeader>
              <PlanFeatures>
                <span className="feature mainFeature">{plan.mainFeature}</span>
                {plan.features.map((feature, index) => (
                  <span key={index} className="feature">
                    {feature}
                  </span>
                ))}
              </PlanFeatures>
              <PlanAction>
                <a href={plan.link} target="_blank"><BuyNowButton css={!plan.featured && highlightGradientsCss[index]}>{primaryButtonText}</BuyNowButton></a>
              </PlanAction>
            </Plan>
          ))}
          <DecoratorBlob/>
        </PlansContainer>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <img src={require('../../images/micontador/Mercado-Pago-Logo.png')} style={{maxWidth: "180px"}}/>
        </HeaderContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
