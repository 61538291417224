import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "images/logo-light.svg";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as LinkedInIcon } from "images/linkedin-icon.svg";
import { ReactComponent as TwitterIcon } from "images/instagram.svg";
import { ReactComponent as YoutubeIcon } from "images/tiktok.svg";

import { Link, animateScroll as scroll } from "react-scroll";

const Container = tw.div`relative bg-gray-900 text-gray-100 -mx-8 -mb-8 px-8`;
const Content = tw.div`max-w-screen-xl mx-auto pt-16 pb-8`
const FiveColumns = tw.div`flex flex-wrap justify-between`;

const Column = tw.div`w-1/2 md:w-1/5 mb-8 md:mb-0 text-sm sm:text-base text-center md:text-left`;
const CompanyColumn = tw.div`text-center md:text-left mb-16 lg:mb-0 w-full lg:w-1/5`;

const ColumnHeading = tw.h5`font-bold uppercase`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const LogoContainer = tw.div`flex items-center justify-center lg:justify-start`;
const LogoImg = tw.img`w-40`;
const LogoText = tw.h5`ml-2 text-xl font-black`;
const Linkexterno = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-100 pb-1 transition duration-300`;

const CompanyAddress = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto lg:mx-0 lg:mr-4 leading-loose text-center lg:text-left hocus:text-primary-300 `;

const SocialLinksContainer = tw.div`mt-4 text-center lg:text-left`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-500 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const CopyrightAndCompanyInfoRow = tw.div`pb-0 text-sm font-normal flex flex-col sm:flex-row justify-between items-center`
const CopyrightNotice = tw.div``
const CompanyInfo = tw.div``

const Divider = tw.div`my-8 border-b-2 border-gray-800`
export default () => {
  return (
    <Container>
      <Content>
        <FiveColumns>
          <CompanyColumn>
            <LogoContainer>
              <LogoImg src={LogoImage} />
            </LogoContainer>
            <a href="https://goo.gl/maps/cAmVqzG8jzCBfkwWA" target="_blank"><CompanyAddress>
              Azucenas #251 &nbsp;&nbsp; 
              Col. Insurgentes 
              Queretaro C.P. 76117
            </CompanyAddress></a>
            <SocialLinksContainer>
              <SocialLink href="https://www.facebook.com/DIMMSYS">
                <FacebookIcon />
              </SocialLink>
              <SocialLink href="https://www.linkedin.com/company/dimmsys-consultoria-en-sistemas-y-ti/?viewAsMember=true">
                <LinkedInIcon />
              </SocialLink>
              <SocialLink href="https://www.tiktok.com/@dimmsys">
                <TwitterIcon />
              </SocialLink>
              <SocialLink href="https://www.tiktok.com/@dimmsys">
                <YoutubeIcon />
              </SocialLink>
            </SocialLinksContainer>
          </CompanyColumn>
          <Column>
            <ColumnHeading>Links rapidos</ColumnHeading>
            <LinkList>
            <Link
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              href="#home">Inicio</Link><br/><br/>
              <Link
              activeClass="active"
              to="soluciones"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              href="#soluciones">Soluciones destacadas</Link> <br/><br/>
              {/*< Link href="/#">Soluciones destacadas</NavLink>*/}
              <Link
              activeClass="active"
              to="servicios"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              href="#servicios">Servicios</Link> <br/><br/>
              <Link
              activeClass="active"
              to="proyectos"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              href="#proyectos">Proyectos de TI</Link> <br/><br/>
              <Link
              activeClass="active"
              to="marcas"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              href="#marcas">Aliados comerciales</Link> <br/><br/>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Servicios</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Linkexterno href="/micontador">MiContador</Linkexterno>
              </LinkListItem>
              <LinkListItem>
                <Linkexterno href="#"></Linkexterno>
              </LinkListItem>
              <LinkListItem>
                <Linkexterno href="#"></Linkexterno>
              </LinkListItem>
              <LinkListItem>
                <Linkexterno href="#"></Linkexterno>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Legal</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Linkexterno href="/privacypolicy">Politica de privacidad</Linkexterno>
              </LinkListItem>
              <LinkListItem>
                <Linkexterno href="#"></Linkexterno>
              </LinkListItem>
              <br/>
              <ColumnHeading>Utilerias</ColumnHeading>
              <LinkListItem>
                <Linkexterno href="https://www.dimmsys.com/resources/Supremo-cliente.exe" download >Supremo</Linkexterno>
              </LinkListItem>
              <LinkListItem>
                <Linkexterno href="#"></Linkexterno>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Contacto</ColumnHeading>
            <LinkList>
              <LinkListItem>
              <Linkexterno href="https://wa.me/4423205530" target="blank">(442) 320-5530</Linkexterno>
              </LinkListItem>
              <LinkListItem>
                <Linkexterno href="mailto:hola@dimmsys.com">hola@dimmsys.com</Linkexterno>
              </LinkListItem>
              <LinkListItem>
                <Linkexterno href="mailto:ventas@dimmsys.com">Ventas</Linkexterno>
              </LinkListItem>
              <LinkListItem>
                <Linkexterno href="mailto:hola@dimmsys.com">Temas administrativos</Linkexterno>
              </LinkListItem>
            </LinkList>
          </Column>
        </FiveColumns>
        <Divider/>
        <CopyrightAndCompanyInfoRow>
          <CopyrightNotice></CopyrightNotice>
          <CompanyInfo></CompanyInfo>
        </CopyrightAndCompanyInfoRow>
      </Content>
    </Container>
  );
};
